/* Montserrat */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../fonts/Montserrat/Montserrat-Thin.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(../fonts/Montserrat/Montserrat-ThinItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../fonts/Montserrat/Montserrat-ExtraLight.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(../fonts/Montserrat/Montserrat-ExtraLightItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/Montserrat/Montserrat-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(../fonts/Montserrat/Montserrat-LightItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/Montserrat/Montserrat-RegularItalic.woff2) format('woff2');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/Montserrat/Montserrat-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/Montserrat/Montserrat-MediumItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/Montserrat/Montserrat-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/Montserrat/Montserrat-SemiBoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/Montserrat/Montserrat-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/Montserrat/Montserrat-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../fonts/Montserrat/Montserrat-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(../fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../fonts/Montserrat/Montserrat-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(../fonts/Montserrat/Montserrat-BlackItalic.ttf) format('truetype');
}
/* Syne */
@font-face {
  font-family: 'Syne';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/Syne/Syne-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Syne';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../fonts/Syne/Syne-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Syne';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/Syne/Syne-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Syne';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/Syne/Syne-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Syne';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../fonts/Syne/Syne-ExtraBold.ttf) format('truetype');
}