.mention {
    background-color: $primary;
    border-radius: 6px;
    color: white;
    font-size: .75rem;
    padding: 2px;
}

.user-mention {
    background-color: $primary;
    border-radius: 6px;
    box-shadow: 0 0 5px $primary;
    color: $tertiary;
    font-size: .75rem;
    padding: 2px;
}

.plain-text {
    font-size: .75rem;
}

.comment {
    max-width: 80%;
    line-height: 20px;
}
