.btn {
  &:hover {
    &.btn-primary {
      background-color: $gray-light;
    }

    &.btn-secondary {
      color: $gray-light;
    }

    &:active, &.active, &.show {
      color: var(--bs-btn-active-color);
      background-color: var(--bs-btn-active-bg);
      border-color: var(--bs-btn-active-border-color);
    }
  }
}
