.badge {
    font-family: 'Montserrat';
}
.bg-gray-light {
    background-color: $gray-light !important;
}

.form-control,
.form-select,
.btn,
.form-check-input {
    &:focus,
    &:active:focus {
        box-shadow: none;
    }
}

.btn.show {
    box-shadow: none;
    &:focus,
    &:active:focus {
        box-shadow: none;
    }
}

.form-control:not([disabled]),
.form-select:not([disabled]) {
    &:hover {
        border-color: $gray-light;
    }
}

.form-floating {
    .form-control,
    .form-select {
        border-width: 1px;

        &:focus ~ label {
            color: $primary;
            opacity: 1;
        }
    }
}

.form-select {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.7924 12.9706C10.3921 13.4906 9.6079 13.4906 9.20759 12.9706L6.04322 8.85999C5.53703 8.20243 6.00579 7.25 6.83563 7.25L13.1644 7.25C13.9942 7.25 14.463 8.20243 13.9568 8.86L10.7924 12.9706Z' fill='%231E242E'/%3E%3C/svg%3E%0A");
    background-size: 21px;

    option {
        font-weight: 600;
    }
}

label {
    color: $primary;
    font-family: 'Montserrat';
    font-weight: bold;
}

.btn-primary,
.btn-primary:focus,
.btn-primary:active {
    color: $secondary;
}

.btn-secondary,
.btn-secondary:focus,
.btn-secondary:active {
    background-color: $light;
    color: $primary;
}

a {
    font-family: 'Montserrat';
    text-underline-offset: 4px;
    font-feature-settings: 'pnum' on, 'lnum' on;

    &:hover {
        color: $gray-light;
    }
}

.form-check-label {
    color: $primary;
}

.form-check-input {
    &:hover:not(:checked) {
        border-color: $gray-light;
    }
}

.modal {
    .logo img {
        width: 30vh;
    }

    .btn-close {
        padding: 0.25rem;
    }

    .btn {
        border-radius: 0.5rem;
        font-family: $headings-font-family;

        &.show {
            box-shadow: none;
        }
    }

    .modal-body {
        padding: 0 1.5rem;
    }
}

.btn-close {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath fillRule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clipRule='evenodd' /%3E%3C/svg%3E");
}

.dropdown-toggle {
    border-radius: 0.5rem;

    &.dropdown-toggle-rounded {
        border-radius: 1rem;
    }
}
.dropdown-menu {
    border-radius: 0.5rem;
    top: 4px !important;
    max-height: 300px;
    overflow-y: auto;

    .dropdown-item {
        &:first-child {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem;
        }

        &:last-child {
            border-bottom-left-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
        }

        &:focus {
            background-color: transparent;
        }

        &:hover,
        &.active,
        :active {
            color: var(--bs-dropdown-link-color);
            background-color: $secondary-light;
        }

        &:hover {
            color: $gray-light;
        }
    }
}

.tooltip {
    font-family: 'Montserrat';
    line-height: 0.75rem;
}

.form-control-lg-white {
    font-size: 0.875rem;
    padding: 1rem 0.9375rem;
    border-radius: 1rem;
    border: 0 none;
}

.form-floating {
    .select2-container--bootstrap-5 {
        .select2-selection {
            min-height: $form-floating-height;
            padding: $form-floating-padding-y $form-floating-padding-x;

            > .select2-selection__rendered {
                margin-top: 0.6rem;
                margin-left: 0.25rem;
                @include font-size($form-select-font-size);
            }
        }
    }
}
.select2 ~ .form-label {
    z-index: 1;
}

.select2-container--bootstrap-5 {
    .select2-selection--multiple {
        .select2-selection__rendered {
            .select2-selection__choice {
                background-color: $secondary-dark;

                .select2-selection__choice__remove {
                    width: 0.5rem;
                    height: 0.5rem;
                }

                .select2-selection__choice__display {
                    font-size: 0.7rem;
                }
            }
        }
    }
}

.dropdown-menu {
    box-shadow: $box-shadow;
}

.pagination {
    .page-item {
        margin-right: 0.5rem;

        .page-link {
            border-radius: 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

// Buttons
@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        --bs-btn-border-width: 1px;
    }
}

// Navbar.tsx
.navbar-nav {
    .show > .nav-link,
    .nav-link.active {
        background-color: $secondary-light;
        border-radius: 0.5rem;
    }
}

// Accordion
.accordion-item {
    margin-top: 1rem;
    border-radius: $accordion-inner-border-radius;

    &:first-of-type {
        margin-top: 0;
    }
}
.accordion-button {
    background-color: $secondary;
    border-radius: $accordion-inner-border-radius;
    color: $primary;

    &:after {
        content: none;
        opacity: 0;
        display: none;
    }

    &:not(.collapsed) {
        background-color: #f1ece8;
    }

    &:focus {
        border: 0 none;
        box-shadow: none;
    }
}

.accordion {
    .accordion {
        border: 1px solid #f1ece8;
        border-radius: var(--bs-accordion-border-radius);
    }
}

// Offcanvas
.offcanvas {
    &.offcanvas-end,
    &.offcanvas-start {
        width: 50vw;

        &.offcanvas-lg {
            width: 75vw;
        }
    }
    &.offcanvas-top,
    &.offcanvas-bottom {
        height: 50vw;

        &.offcanvas-lg {
            height: 75vw;
        }
    }
}

// Form
input,
textarea {
    -webkit-user-select: text; /* Chrome, Opera, Safari */
    -moz-user-select: text; /* Firefox 2+ */
    -ms-user-select: text; /* IE 10+ */
    user-select: text; /* Standard syntax */
}
