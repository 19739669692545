.offcanvas {
  &.offcanvas-full {
    width: 100vw;
  }

  &.offcanvas-large {
    width: 75vw;
    border-top-left-radius: 1rem;

    .offcanvas-body {
      border-top-left-radius: 1rem;
    }

    .offcanvas-header {
      border-top-left-radius: 1rem;
    }
  }

  &.offcanvas-full, &.offcanvas-large {
    .offcanvas-body {
      padding: 0;
      display: flex;
      flex-direction: column;

      .sidebar {
        width: 25%;
        padding: 0 1.5rem;
      }

      .content {
        flex: 1 0 0%;
        padding: 0 1.5rem;
      }

      .header {
        background-color: $primary-dark;
        color: $white;
        height: 4rem;
        display: flex;
        align-items: center;
        font-size: .8rem;
        font-weight: 600;

        .sidebar {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .content {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .menu {
          padding: 0;
          margin: 0;
          height: 4rem;
          list-style: none;
          display: flex;
          align-items: center;
          justify-content: space-around;
          font-size: 1.05rem;
          position: relative;

          &.disabled {
            a {
              pointer-events: none;
              color: rgba(255, 255, 255, .6);
            }

            &:after {
              background-color: hexa-opacity($tertiary, 0.8);
            }
          }

          li {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            cursor: pointer;
            position: relative;
            margin-right: 2.5rem;

            &:after {
              content: ' ';
              display: block;
              height: 0;
              width: 0;
              border-radius: .5rem;
              background-color: $tertiary;
              position: absolute;
              bottom: 0;
              transition: all 500ms cubic-bezier(0.52, 0.16, 0.24, 1);
            }

            &.active {
              &:after {
                height: .5rem;
                width: 100%;
              }
            }

            &:last-child {
              margin-right: 0;
            }

            a {
              color: $white;
              text-decoration: none;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: all 500ms cubic-bezier(0.52, 0.16, 0.24, 1);

              &:hover {
                color: rgba(255, 255, 255, .8);
              }
            }
          }
        }
      }

      .body {
        background-color: $secondary;
        flex: 1 0 0%;
        display: flex;

        & > div {
          padding: 1.875rem 0;
        }

        .sidebar {
          background-color: $white;
          border-top-right-radius: 1rem;
          color: $primary;
          padding-left: 1.5rem;
          padding-right: 1.5rem;

          .sidebar-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h5 {
              font-size: 1.2rem;
              font-weight: 600;
              margin: 0;
            }
          }

          .sidebar-content {
            h6 {
              font-size: .95rem;
              font-weight: 600;
              margin: 1.5rem 0 0.5rem;
            }

            b, p, i {
              font-size: .8rem;
              display: block;
              font-weight: 400;
              margin: 0;
            }

            b {
              font-weight: 600;
            }

            .placeholder {
              min-height: 0.8rem;
            }
          }
        }

        .content {
          padding-left: 3.5rem;
          padding-right: 3.5rem;
          display: flex;
          flex-direction: column;

          header {
            height: 2.5rem;
            display: flex;
            justify-content: space-between;
            align-items: start;
            margin-bottom: 1.5rem;

            .infos {
              display: flex;
              h5 {
                font-size: 1.2rem;
                font-weight: 600;
                margin: 0;

                .placeholder {
                  min-height: 1.2rem;
                }
              }
              h6 {
                margin-left: .5rem;
                font-size: 1.1rem;
              }
            }

            .actions {
              height: 100%;

              button {
                height: 100%;
                font-size: .9rem;
                border-radius: 2rem;

                &.btn-light {
                  background-color: $white;
                  border-color: $white;
                  color: $primary;

                  &:disabled, .disabled {
                    background-color: #ccc;
                    border-color: #ccc;

                    &.btn-loading {
                      color: #ccc;
                    }
                  }

                  &:hover {
                    background-color: tint-color(#ddd, $btn-hover-bg-tint-amount);
                    border-color: tint-color(#ddd, $btn-hover-bg-tint-amount);
                  }

                  &:active, .active {
                    background-color: tint-color(#ccc, $btn-hover-bg-tint-amount);
                    border-color: tint-color(#ccc, $btn-hover-bg-tint-amount);
                  }
                }
              }
            }
          }

          main {
            flex: 1 0 0%;
            overflow-y: auto;
            padding: 0;

            .product {
              border-radius: 1.5rem;
              border: 1px solid #ECE5E1;
              background: #FFF;
              margin-bottom: .5rem;

              &.open {
                .product-header {
                  padding: 2rem 1.5rem;

                  .product-info {
                    .img {
                      width: 3.5rem;
                      height: 3.5rem;
                    }
                  }
                }
              }

              .product-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                padding: 1rem 1.5rem;
                height: 2.5rem;
                box-sizing: content-box;

                .product-info {
                  display: flex;
                  align-items: center;

                  .img {
                    width: 2.5rem;
                    height: 2.5rem;
                    border-radius: .25rem;
                    margin-right: 1rem;
                    background-size: cover;
                    background-position: center;
                  }

                  div {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-around;
                    height: 2.5rem;

                    p {
                      margin: 0;
                    }

                    p.name {
                      font-size: 1rem;
                      cursor: pointer;

                      &:hover {
                        text-decoration: underline;
                      }
                    }

                    p.ref {
                      color: $primary;
                      display: flex;
                      align-items: center;
                    }
                  }
                }

                .product-actions {
                  display: flex;
                  align-items: center;

                  * {
                    margin-right: .5rem;
                    max-height: 2.5rem;

                    &:last-child {
                      margin-right: 0;
                    }
                  }

                  .dropdown {
                    max-height: unset;

                    .dropdown-menu {
                      max-height: unset;
                    }

                    .btn {
                      border: 1px solid $primary-dark;
                      font-size: .875rem;

                      &:after {
                        color: #999CA0;
                      }
                    }
                  }

                  button {
                    height: 2.5rem;
                    background-color: transparent;
                    border: 0 none;
                    color: $primary;
                    font-size: 1.25rem;
                    position: relative;
                    z-index: 1;

                    &.stretched-link {
                      z-index: 0;
                      position: unset;
                    }

                    &:hover {
                      color: $gray-light;
                    }
                  }
                }
              }

              .product-body {
                width: 100%;
                position: relative;
                padding: 1.5rem;

                .product-status-line {
                  height: 2rem;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 1.5rem 0;

                  hr {
                    position: absolute;
                    border-top: 1px dashed #93979F;
                    width: 100%;
                    left: 0;
                    padding: 0 1.5rem;
                    display: block;
                  }

                  label {
                    padding: .375rem .625rem;
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    border-radius: 1rem;
                    background-color: #F8F4F2;
                    border: 2px solid #F8F4F2;
                    color: $primary;
                    font-size: 0.75rem;
                    font-weight: 600;
                    line-height: 1.125rem;
                    letter-spacing: -0.0075rem;
                    z-index: 2;
                    cursor: pointer;

                    &.checked {
                      border-color: $primary;
                      background: $tertiary;
                      cursor: default;
                    }

                    &.disabled {
                      color: #BFABA0;
                      cursor: default;
                    }

                    input[type="radio"] {
                      accent-color: $primary;
                    }
                  }
                }

                .product-message {
                  background-color: transparent;
                  border: 1px solid #ECE5E1;
                  color: $primary;
                  height: auto;
                  width: 100%;
                  border-radius: 0.75rem;
                  margin: 1.5rem 0 0;
                  padding: .75rem;
                  display: flex;

                  textarea {
                    border: 0 none;
                    flex: 1 0 0%;
                    outline: none;
                  }

                  div {
                    height: 100%;
                    display: flex;
                    align-items: flex-end;
                    margin-left: 1rem;
                  }
                }

                .product-last-message {
                  width: 100%;
                  margin: 1.5rem 0 0;
                  border: 1px solid #ECE5E1;
                  background-color: #EBFE6F;
                  color: $primary;
                  padding: .75rem 1.5rem;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  border-radius: .75rem;
                }

                .product-properties {
                  ul {
                    list-style: none;
                    padding: 0;

                    li {
                      display: flex;
                      align-items: center;

                      h6 {
                        &:after {
                          content: ' : ';
                          margin-right: .5rem;
                        }
                      }
                    }
                  }
                }
              }
            }

            .notification {
              border-radius: 1.5rem;
              border: 1px solid #ECE5E1;
              background: #FFF;
              margin-bottom: .5rem;

              &.open {
                .notification-header {
                  padding: 2rem 1.5rem;

                  .notification-info {
                    .img {
                      width: 3.5rem;
                      height: 3.5rem;
                    }
                  }
                }
              }

              .notification-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                padding: 1rem 1.5rem;
                height: 2.5rem;
                box-sizing: content-box;

                .notification-info {
                  display: flex;
                  align-items: center;

                  .img {
                    width: 2.5rem;
                    height: 2.5rem;
                    border-radius: .25rem;
                    margin-right: 1rem;
                    background-size: cover;
                    background-position: center;
                  }

                  div {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-around;
                    height: 2.5rem;

                    p {
                      margin: 0;
                    }

                    p.name {
                      font-size: 1rem;
                    }

                    p.ref {
                      font-size: 0.625rem;
                      color: $text-muted;
                      font-style: italic;
                    }
                  }
                }

                .notification-actions {
                  display: flex;
                  align-items: center;

                  * {
                    margin-right: .5rem;
                    max-height: 2.5rem;

                    &:last-child {
                      margin-right: 0;
                    }
                  }

                  .dropdown {
                    max-height: unset;

                    .dropdown-menu {
                      max-height: unset;
                    }

                    .btn {
                      border: 1px solid $primary-dark;
                      font-size: .875rem;

                      &:after {
                        color: #999CA0;
                      }
                    }
                  }

                  button {
                    height: 2.5rem;
                    background-color: transparent;
                    border: 0 none;
                    color: $primary;
                    font-size: 1.25rem;
                    position: relative;
                    z-index: 1;

                    &.stretched-link {
                      z-index: 0;
                      position: unset;
                    }

                    &:hover {
                      color: $gray-light;
                    }
                  }
                }
              }

              .notification-body {
                width: 100%;
                position: relative;
                padding: 1.5rem;
              }
            }
          }
        }
      }
    }
  }

  &.offcanvas-collection {
    margin: 1rem 2rem;
    border-radius: 1.5rem;
    background-color: #f8f4f2;

    .form-control, .form-select, .form-check-input {
      background-color: $white;

      &:checked {
        background-color: $primary;
      }
    }

    .form-collection-add {
      --bs-btn-color: var(--bs-white);
      --bs-btn-bg: var(--bs-primary);
      --bs-btn-border-color: var(--bs-primary);
      --bs-btn-hover-color: var(--bs-white);
      --bs-btn-hover-bg: #2b2f3c;
      --bs-btn-hover-border-color: #282c38;
      --bs-btn-focus-shadow-rgb: 81, 85, 98;
      --bs-btn-active-color: var(--bs-white);
      --bs-btn-active-bg: #282c38;
      --bs-btn-active-border-color: #262935;
      --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      --bs-btn-disabled-color: var(--bs-white);
      --bs-btn-disabled-bg: var(--bs-primary);
      --bs-btn-disabled-border-color: var(--bs-primary);
    }

    .btn-light {
      background-color: $white;
      border: 1px solid $white;

      &:hover {
        background-color: $secondary;
        border-color: $gray-light;
      }
    }

    .offcanvas-header {
      padding-bottom: 0;

      h2 {
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 700;
      }

      .btn-close {
        color: #fff;
        background-color: #000;
        opacity: 1;
        border-radius: 2rem;
        padding: 0.5rem;
        &:after {
          content: ' ';
          display: block;
          width: 100%;
          height: 100%;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' viewBox='0 0 20 20' fill='white' %3E%3Cpath fillRule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clipRule='evenodd' /%3E%3C/svg%3E");
        }
      }
    }

    .offcanvas-body {
      padding: 0 var(--bs-offcanvas-padding-x);

      .controls{
        .left-button,
          .right-button {
            border-radius: 100%;
            display: inline-block;
            font-size: 1rem;
            height: 2.5rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 2.5rem;
            z-index: 1000;
          }

        .btn:hover.btn-primary{
          background-color: $primary !important;
          color: $white;
          box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        }

        .left-button {
          left: 0;
          transform: translateX(-45%);
        }

        .right-button {
          right: 0;
          transform: translateX(45%);
        }
      }

      h3 {
        font-size: 1.25rem;
      }

      .timestamp {
        font-weight: normal;
      }

      form {
        .form-floating {
          .select2-container--bootstrap-5 {
            .select2-selection {
              background-color: $white;
            }
          }
        }

        .footer {
          position: sticky;
          bottom: 0;
          background-color: #fff;
          padding: 1rem;
          z-index: 2;

          .btn {
            border: 2px solid $primary;
            box-shadow: .25rem .375rem 0 0 $primary;
            transition: transform .15s ease-in-out, box-shadow .15s ease-in-out;
            border-radius: 2rem;

            &:active:focus {
              transform: translate(.25rem, .375rem);
              box-shadow: none;
            }
          }
        }
      }

      .preview {
        background-color: $white;
        height: 500px;
        overflow: hidden;
        position: relative;

        .reset-btn{
          background: rgba(0, 0, 0, 0.6);
          border-radius: 5px;
          border: none;
          color: #fff;
          cursor: pointer;
          padding: 8px 12px;
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 10;
        }
      }
    }
  }
}

.offcanvas-backdrop {
  &.offcanvas-backdrop-large {
    background-color: $dark;
    opacity: 0.5;
  }
}
.select2-container--bootstrap-5 .select2-dropdown.select2-dropdown--below {
  background-color: $white;
}
