select.form-control[multiple] {
    height: auto;
}

input:required + label:after,
select:required + label:after,
textarea:required + label:after {
    content:" *";
    color: $danger;
}

.form-floating {
    input[type=color] {
        width: 100%;
    }
}
