#upload-system {
  display: flex;
  width: 100vw;
}

#upload-message {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: end;
  padding: 0;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100vw;
  z-index: $zindex-modal + 1;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    height: 2.5rem;
    min-width: 400px;
    max-width: 80vw;
    position: absolute;
    bottom: -2.5rem;
    transition: bottom 150ms;
    cursor: pointer;
    padding: 0 1rem;

    &.drop-possible {
      background: $primary;
      color: $white;

      &:hover {
        background-color: darken($primary, 10%);
      }
    }

    &.drop-not-possible, &.error {
      background: $primary;
      color: $danger;

      &:hover {
        background-color: darken($primary, 10%);
      }
    }

    &.uploading {
      background-color: $primary;
      color: $tertiary;

      &:hover {
        background-color: darken($primary, 10%);
      }
    }

    &.uploaded {
      background-color: $primary;
      color: $success;

      &.on-error {
        color: $danger;
      }

      &:hover {
        background-color: darken($primary, 10%);
      }
    }

    &.success {
      background-color: $success;
      color: $primary;

      &:hover {
        background-color: darken($success, 10%);
      }
    }
  }

  &.on-upload {
    &.uploading {
      div.uploading {
          bottom: 1rem;
      }
    }

    &.uploaded {
      div.uploaded {
          bottom: 1rem;
      }
    }

    &.success {
      div.success {
          bottom: 1rem;
      }
    }

    &.error {
      div.error {
          bottom: 1rem;
      }
    }
  }
}

body.on-drag {
  overflow: hidden;

  #upload-message {
    &.drop-possible {
      div.drop-possible {
        bottom: 1rem;
      }
    }

    &.drop-not-possible {
      div.drop-not-possible {
        bottom: 1rem;
      }
    }
  }

  #upload-modal {
    .content {
      .area {
        &:hover {
          background-color: $secondary-dark;
        }
      }
    }
  }
}

body.show-upload-modal {
  overflow: hidden;

  #upload-system {
    //background: radial-gradient(circle farthest-side, transparent, $gray-light);
    &.is-droppable {
      & > div.is-droppable {
        bottom: -2.5rem;
      }
    }

    &.is-not-droppable {
      & > div.is-not-droppable {
        bottom: -2.5rem;
      }
    }

    &.in-progress {
      & > div.in-progress {
        bottom: -2.5rem;
      }
    }
  }

  #upload-modal {
    left: 0;
  }
}

@keyframes fade-in-out {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

#upload-modal {
  .modal-content {
    background-color: $body-bg;
    display: flex;
    flex-direction: column;
    z-index: 10;
  }

  .btn-import {
    font-size: 0.7rem;
    block-size: 8em;
  }

  .content {
    display: flex;
    height: 100%;
    padding-bottom: $modal-inner-padding;

    .area {
      width: 66%;
      border-radius: 1rem;
      margin-right: 2rem;
      display: flex;

      div.col {
        max-width: 250px;
      }

      &.onDrag {
        border: 2px $secondary-darken dashed;
      }

      .import-placeholder {
        background-color: transparent;
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $primary;
        font-size: 1rem;

        i {
          font-size: 2rem;
          margin-bottom: .5rem;
        }

        label {
          color: $primary;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        input[type=file] {
          display: none;
        }
      }

      &.show-placeholder {
        justify-content: center;
        align-items: center;
        background-color: $secondary-dark;
        height: 100%;

        .import-placeholder {
          display: flex;
        }

        .files {
          display: none;
        }
      }

      .files {
        width: 100%;
        overflow-y: auto;

        .uploaded-card {
          background-color: $white;
          border: 0 none;

          .card-header {
            background-color: transparent;
            border-bottom: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .badge {
              background-color: $gray-light;
              text-transform: uppercase;

              &.uploading {
                color: $primary;
              }
            }

            .trash {
              color: $danger;
            }

            .retry {
              color: $primary;
            }
          }

          .img {
            margin: 0 1rem;
            height: 100%;
            background-color: $gray-light;
            display: flex;
            align-items: center;
            justify-content: center;
            max-height: 150px;

            i, img {
              max-height: 150px;
              max-width: 100%;
              width: 100%;
              height: 150px;
            }

            img {
              object-fit: contain;
            }

            i {
              font-size: 2rem;
              color: $primary;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .card-body {
            text-align: center;
            font-size: 0.75rem;

            .card-title {
              text-overflow: ellipsis;
              margin-bottom: 0.375rem;
              white-space: nowrap;
              overflow: hidden;
            }

            .card-meta {
              margin: 0;
              color: $secondary-darken;
              font-size: 0.75rem;
            }
          }
        }
      }
    }

    .form {
      width: 34%;
      border-left: 1px solid $secondary;
      overflow-y: auto;
      overflow-x: hidden;
      background: white;
      padding: 2rem;
      border-radius: 2rem;
      display: flex;
      flex-direction: column;

      .form-content {
        flex: 1 0;
        overflow-x: hidden;
        overflow-y: auto;
      }
      .form-actions {
        display: flex;
        justify-content: end;
        align-items: center;

        button {
          margin-right: .5rem;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
body.showImportCollectionModal {
  overflow: hidden;

  #importCollectionModal {
    left: 0;
    width: 100vw;
    padding: 2rem 3.5rem 3.5rem 3.5rem;
  }
}
#importCollectionModal {
  position: fixed;
  top: 0;
  width: 0;
  height: 100vh;
  transition: left 0.44s 0.2s cubic-bezier(0.52, 0.16, 0.24, 1), width 0.44s 0.2s cubic-bezier(0.52, 0.16, 0.24, 1);
  left: 100%;
  background-color: $body-bg;
  padding: 0;
  display: flex;
  flex-direction: column;
  z-index: 21;
  overflow: hidden;

  .content {
    flex-direction: column;
    flex: 1 0;
    overflow: auto;

    .collection-form {
      height: 10%;
    }

    .preview {
      flex: 1 0;
      height: 80%;
    }

    .actions {
      height: 10%;
      display: flex;
      justify-content: flex-end;
    }

    .area {
      width: 100%;
    }
  }
}
