.modal-choose-items {
  .modal-header {
    padding-top: 1rem;

    &.modal-with-search {
      padding-top: 0.5rem;
    }

    .form-control-lg-white {
      width: 60%;
      max-width: 400px;
      margin: 0 auto;
      border-radius: 0;
      padding-left: 1em;
      padding-right: 0;
    }
  }

  .modal-body {
    min-height: 25vh;
    background-color: $secondary-light;
    padding: 1.5rem;
  }
}
