@charset "utf-8";

@function hexa-opacity($color, $opacity) {
  @return rgba(red($color), green($color), blue($color), $opacity);
}

@import "fonts";
@import "variables";
@import "pre-bootstrap";
@import "bootstrap/scss/bootstrap";
@import 'select2/dist/css/select2.min.css';
@import 'select2-bootstrap-5-theme/src/_include-all.scss';
@import "@fortawesome/fontawesome-pro/scss/fontawesome";
@import "@fortawesome/fontawesome-pro/scss/regular";
@import "@fortawesome/fontawesome-pro/scss/solid";
@import "@fortawesome/fontawesome-pro/scss/brands";
@import "reboot";
@import "icons";
@import "accordion";
@import "card-item";
@import "buttons";
@import "form";
@import "table";
@import "upload-system";
@import "modals";
@import "bimbo-share";
@import "choose-items";
@import "grouped-actions-modal";
@import "dropzone";
@import "offcanvas";
@import "form-collection-entity";
@import "kanban";
@import 'mention';
@import "dashboard";

.dropdown-menu.dropdown-select2 {
  padding: 0;
  .dropdown-item {
    padding: 0;
    width: 100%;
    .select2 {
      width: 100%;

      .select2-selection {
        background: #fff;
      }
    }
  }
}

body > .select2-container.select2-container--bootstrap-5.select2-container--open {
  margin-top: -5px;
}

.choice-filter .select2-selection__rendered {
  display: none !important;
}

.stretched-link-sibling {
  position: relative;
  z-index: 1;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

body {
  display: flex;
  max-width: 100vw;
}

.content-center {
  width: 100vw;
  height: 100vh;
  position: static;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-password{
  position: relative;
    i{
      cursor: pointer;
      display: flex;
      align-items: center;
      position: absolute;
      z-index: 1;
      right: 11px;
      top: 0;
      height: 100%;
    }
}

#root {
  width: 100%;
  display: flex;
}

header#main-menu {
  width: $menu-width;
  transition: margin-left .44s .2s cubic-bezier(0.52, 0.16, 0.24, 1);
  min-width: 256px;
  z-index: 20;

  &.minify {
    margin-left: -1 * ($menu-width - .5rem);

    .menu {
      #menu-reduce {
        transform: rotate(180deg);
      }
    }
  }

  &.hidden {
    .menu {
      margin-left: calc(-1 * ($menu-width + 20px));
    }
  }

  .menu {
    width: 100%;
    height: 100vh;
    background-color: $primary;
    border-radius: 0 1rem 1rem 0;
    position: sticky;
    top: 0;
    padding: 24px;
    transition: margin-left .44s .2s cubic-bezier(0.52, 0.16, 0.24, 1);

    nav {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .organization {
        height: 2rem;
        display: flex;

        .icon {
          width: 2rem;
          background-color: $light;
          border-radius: .5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 14px;
          height: 100%;

          .logo {
            content: ' ';
            width: 1.5rem;
            height: 1.5rem;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
          }
        }

        .data {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1 0 0;

          .name {
            font-size: 14px;
            line-height: 17.5px;
            font-weight: 600;
            text-decoration: none;
            color: $tertiary;
          }

          .links {
            font-size: .75rem;
            display: flex;
            align-items: center;

            a {
              text-decoration: none;
              transition: color 500ms;

              & + a {
                margin-left: .5rem;
              }

              &.settings {
                width: .75rem;
                height: .75rem;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                  color: $gray-light;
                }

                &.active {
                  color: $secondary-dark;

                  &:hover {
                    color: $secondary;
                  }
                }
              }
            }
          }
        }
      }

      .menu-list {
        list-style: none;
        padding-left: 0;

        li {
          a {
            font-size: .825rem;
            text-decoration: none;
            font-family: $font-family-sans-serif;
            font-weight: 600;
            display: grid; 
            grid-template-columns: 22px auto;
            align-items: center;
            justify-content: flex-start;
            padding: .5rem;
            border-radius: .5rem;
            transition: color 500ms;

            i {
              font-size: 0.775em;
              margin-right: 0.5rem;
            }

            &:hover {
              color: $tertiary;
            }

            &.active {
              color: $tertiary;
            }
          }

          &.separator {
            margin: .5rem 1rem;
            height: 1px;
            background-color: $gray-light;
          }
        }
      }

      .account {
        display: flex;
        font-size: 14px;
        user-select: none;
        align-items: center;
        justify-content: space-between;

        span {
          text-decoration: none;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-family: $headings-font-family;
          overflow: hidden;

          &:hover {
            .icon {
              color: $primary;
            }
          }

          .icon {
            width: 32px;
            height: 32px;
            border-radius: 32px;
            background-color: $tertiary;
            font-size: 14px;
            margin-right: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
          }
          .name {
            overflow: hidden;
            flex: 1 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: $link-color;
            font-weight: 600;
            &:hover {
              color: $link-hover-color;
            }
          }
        }

        .logout {
          cursor: pointer;
          color: $link-color;

          &:hover {
            color: $link-hover-color;

            &:active, &.active, &.show {
              color: var(--bs-btn-active-color);
              background-color: var(--bs-btn-active-bg);
              border-color: var(--bs-btn-active-border-color);
            }
          }
        }
      }
    }

    #menu-reduce {
      position: absolute;
      right: -32px;
      top: 50%;
      margin-top: -32px;
      width: 64px;
      height: 64px;
      outline: 0;
      border: 0 none;
      background: transparent;
      display: flex;
      padding: 0;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      transition: transform .44s .2s cubic-bezier(0.52, 0.16, 0.24, 1);
    }
  }
}

main {
  padding: $main-padding;
  flex: 1 0 0;
  position: relative;
  overflow-x: hidden;

  header {
    margin-bottom: 1.5rem;

    h1 {
      margin: 0;
    }

    .header-right {
      display: flex;

      .btn-disabled {
        opacity: .5;
      }

      button {
        margin-right: .5rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    #search {
      background-color: $white;
    }

    #search ~ i {
      position: absolute;
      right: calc(0.9375rem + 18px);
      top: 0;
      z-index: 2;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: text;
    }
  }
}



// Table

table.table {
  th {
    font-family: $headings-font-family;
    font-size: .8rem;
  }

  tr {
    vertical-align: middle;
  }

  tbody {
    tr {
      position: relative;

      td {
        &.actions {
          & > * {
            margin-right: .5rem;
            position: relative;
            z-index: 1;
            height: 1.875rem;
          }

          form {
            display: inline-block;
          }
        }

        &.thumbnail {
          a {
            z-index: 1;
            position: relative;
            width: 32px;
            display: inline-block;
            height: 100%;
          }
        }
      }
    }
  }
}

.text-link {
  color: var(--bs-link-color);
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Montserrat';
  text-underline-offset: 4px;
  font-feature-settings: 'pnum' on, 'lnum' on;

  &:hover {
    color: $gray-light;
  }
}

.box-scrollable {
  display: flex;
  height: 100%;
  flex-direction: column;
  & > * {
    flex: 1 0;
    overflow-y: auto;
    overflow-x: hidden;
  }
  & > *:last-child {
    flex: 0 auto;
    overflow: hidden;
  }
}

.mini-listing {
  min-height: 13rem;
  background-color: $secondary-light;
  border-radius: 1rem;
  padding: 1rem;
  margin: 1rem 0;
}

.form-collection {
  .form-collection-loader {
    display: none;
  }

  &.onload {
    .form-collection-loader {
      display: block;
    }
  }
}

.box-file-edit {
  .preview {
    background-color: $secondary;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    max-height: 50vh;
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 2rem;

    .picture {
      display: block;
      max-width: 100%;
      max-height: 100%;
      background-size: contain;
      width: 100%;
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
}
.choose_collection_import {
  li {
    padding: 0;
    button {
      outline: 0;
      background: transparent;
      border: 0 none;
      width: 100%;
      height: 100%;
      padding: #{$list-group-item-padding-y} #{$list-group-item-padding-x};
      font-weight: bold;
      text-align: left;
      border-radius: #{$border-radius-sm};

      &:hover {
        background-color: $secondary-light;
      }

      &.active {
        background-color: $primary;
        color: $secondary;

        &:hover {
          background-color: $secondary-darken;
        }
      }
    }
  }
}
.badge-status {
  min-width: 4.5rem;
  height: 1.625rem;
  border-radius: 0.5rem;
  color: #fff;
  font-weight: 500;
  font-family: 'Montserrat';
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  width: fit-content;
  outline: none;
  border: 0 none;

  &.status-draft {
    background-color: $text-muted;
  }

  &.status-published {
    background-color: $success;
    color: $dark;
  }

  &.status-unpublished {
    background-color: $danger;
  }

  &.status-archived {
    background-color: $text-muted;
  }

  &.status-finalized {
    background-color: $text-muted;
  }

  &.status-validated {
    background-color: $success;
    color: $dark;
  }

  &.status-rejected {
    background-color: $danger;
  }

  &.status-outputted {
    background-color: $warning;
    color: $dark;
  }

  &.status-online {
    background-color: $success;
    color: $dark;
  }

  &.status-generating {
    background-color: $warning;
    color: $dark;
  }

  &.status-expired {
    background-color: $danger;
  }

  &.status-offline {
    background-color: $text-muted;
  }

  &.status-sent {
    background-color: $info;
  }

  &.status-planned {
    background-color: #1869F5;
  }

  &.status-refused {
    background-color: $warning;
    color: $dark;
  }

  &.status-returned {
      background-color: $info;
  }

  &.status-waited {
    background-color: $warning;
    color: $dark;
  }

  &.status-gift {
      background-color: $text-muted;
  }

  &.status-lost {
      background-color: $text-muted;
  }
}
.list-group {
  &.list-group-border {
    --#{$prefix}list-group-border-width: 1px;
    --#{$prefix}list-group-border-radius: 0.5rem;
  }
}
