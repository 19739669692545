.btn-icon {
  display: flex;
  align-items: center;

  &.btn-icon-only {
    font-size: 1rem;

    &.btn-icon-without-padding {
      border: 0 none;
      padding: 0;
      background-color: transparent;
    }

    &:hover {
      @each $color, $value in $theme-colors {
        &.btn-outline-#{$color} {
           color: darken($value, 10%);
        }
      }
    }
  }

  &.btn-icon-rotate-hover {
    i {
      transition: transform .44s .2s cubic-bezier(0.52, 0.16, 0.24, 1);
    }
    &:hover {
      i {
        transform: rotate(180deg);
      }
    }
  }

  i {
    height: 28px;
    margin: 0 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
