$spacer: 1rem;
$grid-gutter-width: 1rem;

$primary: #323746;
$secondary: #F7F4F2;
$tertiary: #E6FF50;
$success: #85D6B8;
$danger: #FFA083;
$warning: #FFE483;
$info: #8BD5FF;
$light: #ECE5E0;
$dark: #000;

$box-shadow: .25rem .25rem 1rem  rgba($dark, .18);

$primary-dark: #202429;
$gray-light: #BEC3C7;
$secondary-light: $secondary;
$secondary-dark: #ECE5E0;
$secondary-darken: #1E2328;

$input-bg: $secondary-light;
$input-group-addon-bg: $secondary-light;
$link-color: $gray-light;
$link-hover-color: $secondary;
$input-font-weight: 600;

$input-border-color: $secondary-light;
$form-label-color: $secondary-light;

$font-family-sans-serif: 'Montserrat';
$headings-font-family: 'Montserrat';
$headings-font-weight: 600;
$font-weight-base: 600;
$headings-color: $primary;

$font-size-base: 0.75rem;

$h1-font-size: 1.375rem;

$modal-backdrop-bg: $gray-light;

$modal-dialog-margin-y-sm-up: 14rem;

$modal-sm: 360px;
$modal-md: 600px;
$modal-lg: 960px;
$modal-xl: 1368px;

$border-radius: 1rem;
$border-radius-lg: 1rem;
$border-width: 0;
$input-border-width: 1px;
$card-border-width: 1px;

$line-height-base: 1.25;

$modal-header-padding: 1.5rem 1.5rem 1rem;//.6875rem;
$modal-inner-padding: 1.5rem;

$btn-close-width: 1.25rem;
$btn-close-padding-x: .25rem;
$btn-font-weight: 600;

$input-focus-border-color: $primary;

$body-color: $primary;

$body-bg: $secondary-light;

$dropdown-padding-x: .75rem;
$dropdown-padding-y: .75rem;

$dropdown-item-padding-y: .25rem;
$dropdown-item-padding-x: .375rem;

$tooltip-border-radius: 10px;
$tooltip-bg: $light;
$tooltip-color: $gray-light;
$tooltip-opacity: 1;
$tooltip-padding-y: .375rem;
$tooltip-padding-x: .5rem;
$tooltip-font-size: 10px;


// Pagination
$pagination-border-radius: .5rem;
$pagination-bg: $light;
$pagination-color: $primary;
$pagination-hover-color: $light;
$pagination-hover-bg: $primary;
$pagination-active-bg: $primary;
$pagination-active-color: $secondary;
$pagination-focus-box-shadow: none;
$pagination-font-size: .875rem;

// Table
$table-border-width: 1px;
$table-cell-padding-y: .5rem;
$table-cell-padding-x: .5rem;

// Form
$form-select-border-width: 1px;
$form-select-box-shadow: none;
$form-select-focus-box-shadow: none;
$form-label-color: $primary;
$input-border-radius: .5rem;

// Buttons

// Nav

// Custom var (not use on Bootstrap)
$menu-width: 16.25rem;
$main-padding: 2rem 3.5rem 0 3.5rem;

// Accordion
$accordion-border-radius: .5rem;
$accordion-inner-border-radius: .5rem;
$accordion-body-padding-x: 0;
$accordion-body-padding-y: .5rem;

// OffCanvas
