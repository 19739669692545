table.table {
    border-collapse: separate;
    border-spacing: 0 .5rem;

    &.table-collapse {
        border-collapse: collapse;
    }

    &.table-large {
        th {
            font-family: 'Montserrat';
            font-size: 0.8rem;
            font-weight: 500;
            padding-bottom: 1rem;
            border-bottom: 0;
        }

        td {
            &.select {
                label {
                    cursor: pointer;

                    input[type="checkbox"] {
                        display: none;

                        &:checked ~ span:before {
                            display: inline-block;
                            font-style: normal;
                            font-variant: normal;
                            text-rendering: auto;
                            -webkit-font-smoothing: antialiased;
                            font-family: "Font Awesome 6 Pro";
                            content: "\f00c";
                            font-size: 1.1rem;
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            line-height: 1rem;
                        }
                    }

                    span {
                        border: 2px solid $primary-dark;
                        border-radius: 3px;
                        width: calc(1rem + 4px);
                        height: calc(1rem + 4px);
                        display: block;
                        position: relative;
                    }
                }
            }
        }

        tbody {
            tr {
                background-color: #fff;
                height: 3.5rem;

                &.selected {
                    background-color: hexa-opacity($primary, .2);
                }

                td, th {
                    &:first-child {
                        padding-left: 1rem;
                        border-top-left-radius: .5rem;
                        border-bottom-left-radius: .5rem;
                    }
                    &:last-child {
                        padding-right: 1rem;
                        border-top-right-radius: .5rem;
                        border-bottom-right-radius: .5rem;
                    }

                    &.rounded-thumbnail {
                        width: 2rem;
                        text-align: center;
                        vertical-align: middle;

                        i {
                            display: inline-flex;
                            width: 2rem;
                            height: 2rem;
                            border-radius: 50%;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: cover;
                            background-color: $secondary-light;
                            align-items: center;
                            justify-content: center;
                            color: #fff;
                            position: relative;

                            &:before {
                                content: '';
                                display: block;
                                width: 2rem;
                                height: 2rem;
                                border-radius: 50%;
                                background-color: rgba(0, 0, 0, .3);
                                position: absolute;
                            }

                            span {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                text-shadow: 1px 1px 3px $primary;
                                font-weight: bold;
                                font-style: normal;
                            }
                        }
                    }

                    &.multi-line {
                        span {
                            display: block;
                        }

                        i {
                            font-size: .6rem;
                            font-weight: normal;
                            font-style: normal;
                            color: $text-muted;
                        }
                    }

                    .edit-link {
                        text-decoration: none;
                        color: #1869F5;
                        position: relative;
                        z-index: 1;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .row-archived {
        opacity: .5;
    }
}
