.btn {
  &.btn-loading {
    color: transparent;
    position: relative;

    span {
      &.spinner-border,
      &.spinner-grow {
        color: var(--bs-btn-color);
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: calc(-1 * (var(--bs-spinner-height) / 2));
        margin-left: calc(-1 * (var(--bs-spinner-width) / 2));
      }
    }
    i.loading-button-success {
      color: var(--bs-btn-color);
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.btn-large {
    width: 100%;
    padding: 2rem;
    border-radius: .5rem;
    font-size: 1rem;
  }

  &.btn-tertiary {
    background-color: $tertiary;
    color: $primary;
    border: 2px solid $primary;
    font-weight: bold;
    box-shadow: .25rem .375rem 0 0 $primary;
    transition: transform .15s ease-in-out, box-shadow .15s ease-in-out;
    border-radius: 2rem;

    &:active:focus {
      transform: translate(.25rem, .375rem);
      box-shadow: none;
    }

    &:hover {
      background-color: $tertiary;
    }
  }

  &.btn-dashed {
    border-style: dashed !important;
  }
}
