.modal {
  .modal-dialog:not(.modal-fullscreen) .modal-content {
    width: 85%;
    margin: auto;
  }
  .modal-header {
    .modal-block-header {
      display: flex;
      align-content: center;
      justify-content: start;

      i {
        font-size: $h4-font-size;
        margin-right: .5rem;
        cursor: pointer;
        display: flex;
        align-items: center;

        &:hover {
          color: $gray-light;
        }
      }
    }
  }

  .choose-brand, .choose-platform {
    list-style: none;
    padding-left: 0;
    margin-bottom: 1.5rem;

    li {
      margin-top: 1rem;
      &.active {
        button, a {
          background-color: $secondary-light;
          cursor: default;
          pointer-events: none;
        }
      }

      button, a {
        width: 100%;
        padding: ($btn-padding-y * 2) 0;
        font-size: 1.25rem;
      }
    }
  }

  &.thumbnail-modal {
    div.modal-content {
      width: 50%;
    }
    div.content {
      display: grid;
      place-items: center;
      width: 100%;
      //height: 531.25px;
      max-height: 100%;
      margin-bottom: 2em;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .modal-body {
    .grouped-action-choices {
      list-style: none;
      margin-bottom: $modal-inner-padding;
      width: 100%;
      padding: 0;

      li {
        margin-bottom: 1rem;
        border-radius: .5rem;
        height: 3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .5rem 1rem;
        cursor: pointer;

        i.icon {
          color: $primary;
          margin-right: .5rem;
        }
      }
    }
  }
}
