.card-list {
  .card.card-item {
    border: 3px solid $white;
    padding: .3125rem 1rem 1rem;
    max-width: 320px;
    width: 100%;

    a:not(.stretched-link) {
      z-index: 1;
      position: relative;
    }

    &.selected {
      border-color: $gray-light;
    }

    &.click-down {
      border-color: $primary;
    }

    .card-header {
      background-color: transparent;
      padding: 0 0 .5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0 none;

      .states {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .badge {
          margin-right: .25rem;
          width: .9rem;
          color: transparent;
          white-space: nowrap;
          height: .9rem;
          font-size: .5rem;
          user-select: none;
          font-family: 'Montserrat';
        }
      }

      .actions {
        display: flex;

        a, button {
          height: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1rem;
          margin-right: .25rem;
          min-width: 1rem;
          text-decoration: none;

          &:not([style*="display:none"]):last-child {
            margin-right: 0;
          }
        }
      }
    }

    .img {
      max-width: 282px;
      max-height: 176.25px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-bottom: 1rem;
      aspect-ratio: 16/10;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      &button {
        cursor: zoom-in;
      }

      .badge {
        position: absolute;
        bottom: .5rem;
        left: .5rem;
      }
    }

    .card-body {
      padding: 0;
      text-align: center;

      .card-title {
        margin-bottom: 1rem;

        &.card-title-clickable {
          cursor: pointer;
          z-index: 2;

          &:hover {
            color: $gray-light;
          }
        }
      }
    }

    .card-footer {
      border: 0 none;
      background-color: #0000;
      padding: 0;
      text-align: center;

      .card-tags {
        .card-tag {
          font-size: .75rem;
          text-decoration: underline;
          text-underline-offset: .25rem;
        }
      }

      .card-meta {
        margin-bottom: .375rem;
        font-size: .625rem;
        color: $secondary-darken;

        span {
          z-index: 1;
          position: relative;
          text-decoration: underline;
        }
      }

      .card-uses {
        margin-top: .625rem;

        .badge {
          color: $secondary-dark;
          font-size: .625rem;
          font-family: 'Montserrat';
          margin-right: .25rem;
          margin-bottom: .25rem;

          i {
            margin-right: .25rem;
          }
        }
      }
    }

    &:hover {
      .card-header {
        .states {
          .badge {
            width: auto;
            color: $white;
          }
        }
      }
    }
  }

  &.card-list-condensed {
    .card.card-item {
      max-width: 144px;
      border-width: 2px;
      padding: .5rem;
      border-radius: .5rem;

      .card-header {
        padding-bottom: 0.25rem;

        .actions {
          a, button {
            height: 1.5rem;
            &:not(.more-actions):not(.main-action) {
              display: none;
            }

            &.main-action {
              background-color: transparent;
              border: 0 none;
              outline: none;

              &:focus {
                box-shadow: none;
              }
            }
          }
        }
      }

      .img {
        margin-bottom: .5rem;
        .badge {
          display: none;
        }
      }

      .card-body {
        .card-title {
          margin-bottom: .5rem;
          font-size: .625rem;
          a {
            font-size: .625rem;
          }
        }

        .card-meta {
          &.card-meta-date {
            display: none;
          }
        }
        .card-tags {
          display: none;
        }
        .card-uses {
          display: none;
        }
      }

      .card-footer {
        .card-meta {
          &.card-meta-date {
            display: none;
          }
        }
      }
    }
  }

  &.card-list-selectable {
    .card.card-item {
      &:hover {
        border-color: $secondary-dark;
      }
    }
  }

  .card-archived {
    opacity: .5;
  }
}