body.showBimboShareModal {
  overflow: hidden;

  #bimboShareModal {
    left: 0;
    overflow-y: scroll;
  }

  .modal-content {
    width: 70%;
    max-height: 155%;
    .modal-body {
      min-height: 32vh;
    }
  }

  @media screen and (max-width: 1370px) {
    .fa-solid {
      margin-left: 0;
      display: flex;
      justify-content: center;
    }
  }
}

#bimboShareModal {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  transition: left 0.44s 0.2s cubic-bezier(0.52, 0.16, 0.24, 1);
  left: 100%;
  background-color: $body-bg;
  padding: $main-padding;
  padding-bottom: 3.5rem;
  display: flex;
  flex-direction: column;
  z-index: 10;

  .content {
    display: flex;
    height: 100%;
    margin-top: 1rem;

    .selection {
      flex: 1 auto;
      width: 66%;
      padding-right: 2rem;

      .accordion {
        .accordion-button {
          display: flex;
          justify-content: space-between;
          background-color: $light;

          span.title {
            font-size: 1rem;
            display: flex;
            align-items: center;

            i {
              margin-right: .75rem;
            }

            .brand {
              color: $secondary-dark;
              margin-left: .5rem;
              font-size: .75rem;

              &.brand-corporate {
                font-style: italic;
              }
            }
          }

          div {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            & > * {
              width: 2rem;
              height: 2rem;
              border-radius: 2rem;
              display: grid;
              place-items: center;
            }

            span {
              background-color: $white;
              color: #93979F;
              margin-right: .5rem;

              i {
                background-color: inherit;
                color: inherit;
              }
            }

            i, label {
              background-color: $secondary-dark;
              color: $white;
              z-index: 10;
              position: relative;
              cursor: pointer;

              &:hover {
                background-color: $gray-light;
              }
            }
          }
        }

        .accordion-body {
          ul {
            list-style: none;
            padding: 0;

            li {
              border-bottom: 1px solid $secondary;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: .25rem 0;

              &> * {
                display: flex;
                height: 32px;
                align-items: center;
              }

              .name {
                margin-left: .5rem;
              }

              .remove {
                width: 32px;
                height: 32px;
                margin-left: 0.5rem;
                background-color: #0000;
                border: 0 none;
                outline: none;
                display: grid;
                place-items: center;
              }
            }
          }
        }
      }
    }

    .form {
      width: 34%;
      border-left: 1px solid #ECE5E1;
      overflow-y: auto;
      overflow-x: hidden;
      padding-left: 2rem;
      display: flex;
      flex-direction: column;

      form {
        display: flex;
        flex-direction: column;
        flex: 1 auto;
      }

      .form-actions {
        display: flex;
        justify-content: end;

        button {
          margin-right: 0.5rem;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}