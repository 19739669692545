.form-collection-entity {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-wrap: wrap;
  height: auto;

  &.form-collection-entity-multiple {
    height: auto;
    min-height: $form-floating-height;
  }

  [aria-disabled="true"] & {
    cursor: not-allowed;
    border-color: transparent;
    box-shadow: none;
    color: $secondary;

    &:hover {
      border: transparent;
    }
  }

  .form-collection-entity-multiple-item {
    background-color: $secondary-dark;
    align-items: center;
    border: 1px solid $secondary;
    border-radius: .5rem;
    color: $primary;
    display: flex;
    flex-direction: row;
    font-size: .7rem;
    margin: 0 .375rem .375rem 0;
    padding: .35em .65em;
    cursor: pointer;
    font-weight: 600;

    &:hover {
        background-color: #D9D3CF;

      &:before {
        color: #000;
      }
    }

    &:active:focus {
        background-color: #bdb9b4;
    }

    &:before {
        content: "\f00d";
        font-family: "Font Awesome 6 Pro";
        font-weight: 900;
        margin-right: .375rem;
    }
  }
}
