.dropzone {
  width: 100%;
  background: $secondary-light;
  border-radius: .5rem;
  margin-bottom: 1rem;
  padding: 1rem;

  .dropzone-placeholder {
    text-align: center;
    font-size: 1rem;
    width: 100%;
    max-height: 176.25px;
    aspect-ratio: 16/10;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropzone-container {
    border: 2px dashed $gray-light;
    border-radius: .5rem;

    &.mini-listing {
      margin: 0;
    }

    .dropzone-file {
      padding: 1rem;
      border-radius: 0.5rem;
      border: 2px solid #fff;
      background-color: #fff;
      text-align: center;

      img {
        max-width: 100%;
        max-height: 176.25px;
        object-fit: contain;
      }
    }
  }

  &.dropzone-multiple {
    padding: 1rem;

    &.dropzone-file {
      max-width: 144px;
      padding: 0.5rem;
    }
  }

  &.dropzone-has-file {
    .dropzone-container {
      border-style: solid;
    }
  }

  &.dropzone-drag {
    background-color: $secondary;

    .dropzone-container {
      border-color: $primary;
      border-style: dashed;
    }
  }
}

body {
  &.onDrag {
    .dropzone {
      &.dropzone-has-file {
        .dropzone-container {
          border-style: dashed;
        }
      }
    }
  }
}
