.kanban {
  overflow: scroll;
  flex-wrap: nowrap;

  > div {
    border-left: 1px solid $gray-light;
    display: inline-block;
    float: none;
    padding: 0 1rem;

    &:first-child {
      border-left: 0 none;
    }

    h3 {
      color: $gray-light;
      background-color: $light;
      display: inline-block;
      border-radius: 1rem;
      font-size: .75rem;
      padding: 0.25rem 0.75rem;
      margin-bottom: 1rem;
    }

    .card-list {
      .card-item {
        width: 100%;
        margin-bottom: 1rem;
        max-width: none;
        cursor: grab;
        text-align: start;

        .img {
          margin: 0 auto;
        }
      }
    }
  }

  &-column {
    max-height: 100vh;
    overflow-y: scroll;

    &.hovered {
      background-color: $light;
      border: 0.15rem dashed rgba(0, 0, 0, 0.5);
      border-radius: 1rem;
      
      position: relative;
      top: 0;
      bottom: 0;
      height: 100%;

      & > .card {
        display: none;
      }
    }
  }
}
